// extracted by mini-css-extract-plugin
export var footer = "dd";
export var footerBottom = "kd";
export var footerBottom__copyWrapper = "od";
export var footerBottom__footerLink = "nd";
export var footerBottom__footerSocial = "qd";
export var footerBottom__left = "ld";
export var footerBottom__links = "md";
export var footerBottom__textDeveloper = "pd";
export var footerTop = "fd";
export var footerTop__appButton = "id";
export var footerTop__logo = "jd";
export var footerTop__menu = "hd";
export var footerWrapper = "ed";
export var withButton = "gd";